<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{$t('message.goods_arrival')}}</p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <div class="px-3 py-4">
            <div
                class="
                    gc-card
                    rounded-sm
                    mb-4
                    p-relative
                    mr-3
                "
            >
                <div class="gc-card__body px-3 py-2">
                    <div class="text-descr">
                      <el-row :gutter="20"> 
                          <el-form :model="form" :rules="rules" ref="form" class="w-100" label-position="top">
                              <el-col class="height__full" :span="8"> 
                                  <el-form-item :label="$t('message.to_filial')" prop="to_filial_id">
                                      <select-to-filial 
                                          v-model="form.to_filial_id" 
                                          :id="form.to_filial_id" 
                                          :select_only="true"
                                          :placeholder="$t('message.to_filial')" 
                                          class="select__width w-100" 
                                      />
                                  </el-form-item>
                              </el-col>

                              <el-col class="height__full" :span="8">
                                  <el-form-item :label="$t('message.from_filial')">
                                      <select-from-filial 
                                          v-model="form.from_filial_id" 
                                          :id="form.from_filial_id" 
                                          :select_only="true"
                                          :show_all="1"
                                          :placeholder="$t('message.from_filial')" 
                                          class="select__width w-100" 
                                      />
                                  </el-form-item>
                              </el-col>
                              
                              <el-col class="height__full" :span="8">
                                  <el-form-item :label="$t('message.reciever')" prop="all_client_id">
                                    <select-client
                                      :size="'large'"
                                      class="w-100"
                                      :placeholder="$t('message.reciever')"
                                      :id="form.all_client_id"
                                      :query="{from_filial_id: form.from_filial_id, to_filial_id: form.to_filial_id}"
                                      v-model="form.all_client_id"
                                      >
                                    </select-client>
                                  </el-form-item>
                              </el-col>
                          </el-form>
                      </el-row>  
                    </div>
                </div>
            </div>
        </div>
        <el-row v-loading="loadingData">   
            <el-col :span="24">
                <div class="gc-card__body px-3 py-4 mm_custom_input_padding">
                    <div class="app-form__group mb-0">
                        <div
                            class=" 
                                gc-card
                                rounded-sm
                                mb-4
                                p-relative
                                mr-3
                                px-3 py-3
                            "
                        >
                            <div>
                                <el-table 
                                    header-row-class-name="header__class"
                                    row-class-name="row__class"
                                    :data="dealProductsList" 
                                    show-summary 
                                    :summary-method="getSummaries" 
                                    border stripe 
                                    style="width: 100%">
                                    <el-table-column
                                        type="index"
                                        width="50">
                                    </el-table-column>
                                    <el-table-column :label="$t('message.party')" width="90">
                                        <template slot-scope="scope"> {{ scope.row.transfer_id }} </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.deal')" width="90">
                                        <template slot-scope="scope"> {{ scope.row.deal_id }} </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.reciever')">
                                        <template slot-scope="scope"> 
                                            <span>
                                                <span v-if="scope.row.reciever">
                                                    {{'ID: ' + scope.row.reciever.custom_id }} <br> {{ scope.row.reciever.name}} 
                                                </span>
                                                <span v-else>
                                                    <span v-if="scope.row.client">
                                                        {{'ID: ' + scope.row.client.custom_id }} <br> {{ scope.row.client.name}} 
                                                    </span>
                                                </span>
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.name')">
                                        <template slot-scope="scope"> 
                                            <span v-show="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                                            <span v-show="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.barcode')">
                                        <template slot-scope="scope"> 
                                            <span>{{ scope.row.barcode }} </span>    
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.quantity_y')" width="80">
                                        <template slot-scope="scope"> 
                                            <span v-show="scope.row.type === 'product'">{{ scope.row.remainder }} </span>    
                                            <span v-show="scope.row.type === 'package'"> 1 </span>    
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.current_quantity')" width="95">
                                        <template slot-scope="scope">
                                            <div v-show="!scope.row.totalQuantity && scope.row.type === 'product'">
                                                <el-input
                                                    :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
                                                    @input="checkValue(scope.row)"
                                                    v-model="scope.row.incoming_quantity"
                                                ></el-input>
                                            </div>
                                            <span v-show="scope.row.totalQuantity">
                                                {{scope.row.totalQuantity}}
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="columns.weight.title" width="130">
                                        <template slot-scope="scope">
                                            <el-input 
                                                v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                                                class="mm_custom_input"
                                                :min="0" 
                                                :disabled="true"
                                                type="number" 
                                                size="mini" 
                                                v-model="scope.row.weight"
                                            >
                                                <template slot="append"></template>
                                            </el-input>

                                            <span v-show="scope.row.type === 'package'"> 
                                                {{ scope.row.total_weight }}  /   {{ parseFloat(scope.row.total_weight * $kg_to_pound).toFixed(3) }} 
                                            </span>
                                            <span v-show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"> 
                                                {{ showWeight(scope.row) }}  /  {{ parseFloat((showWeight(scope.row) * $kg_to_pound)).toFixed(3) }}
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="columns.item_weight.title" width="130">
                                        <template slot-scope="scope">
                                            <el-input 
                                                v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                                                class="mm_custom_input"
                                                :min="0" 
                                                :disabled="showInputCheck(scope.row)"
                                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                                type="number" 
                                                size="mini" 
                                                v-model="scope.row.item_weight"
                                            >
                                                <template slot="append">{{ $t('message.kg') }}</template>
                                            </el-input>

                                            <el-input 
                                                v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 && !showInputCheck(scope.row))"
                                                class="mt-1 mm_custom_input"
                                                :min="0" 
                                                @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                                type="number" 
                                                size="mini" 
                                                v-model="scope.row.item_weight_in_pounds"
                                            >
                                                <template slot="append">{{ $t('message.lbs') }}</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="columns.width.title" v-if="columns.width.show" width="110">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-show="showInputCheck(scope.row)"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'width')"
                                                v-model="scope.row.width"
                                            >
                                                <template slot="append">{{ $t('message.sm') }}</template>
                                            </el-input>

                                            <el-input
                                                v-show="showInputCheck(scope.row)"
                                                class="mt-1 mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                                                v-model="scope.row.width_on_inches"
                                            >
                                                <template slot="append">{{ $t('message.inch') }}</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="columns.height.title" v-if="columns.height.show" width="110">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-show="showInputCheck(scope.row)"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'height')"
                                                v-model="scope.row.height"
                                            >
                                                <template slot="append">{{ $t('message.sm') }}</template>
                                            </el-input>

                                            <el-input
                                                v-show="showInputCheck(scope.row)"
                                                class="mt-1 mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                                                v-model="scope.row.height_on_inches"
                                            >
                                                <template slot="append">{{ $t('message.inch') }}</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="columns.length.title" v-if="columns.length.show" width="110">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-show="showInputCheck(scope.row)"
                                                class="mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'length')"
                                                v-model="scope.row.length"
                                            >
                                                <template slot="append">{{ $t('message.sm') }}</template>
                                            </el-input>

                                            <el-input
                                                v-show="showInputCheck(scope.row)"
                                                class="mt-1 mm_custom_input"
                                                :min="0"
                                                type="number"
                                                size="mini"
                                                @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                                                v-model="scope.row.length_on_inches"
                                            >
                                                <template slot="append">{{ $t('message.inch') }}</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.comment')">
                                        <template slot-scope="scope"> 
                                            <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                                            <span  v-show="!scope.row.hasOwnProperty('comment')">{{scope.row.containerType ? scope.row.containerType.name : ''}}</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="$t('message.update')" width="85">
                                        <template slot-scope="scope"> 
                                            <el-button v-if="scope.row.type == 'product' && scope.row.batches && scope.row.batches.length > 1"
                                                @click="showAndUpdateBatches(scope.row)" 
                                                type="primary" icon="el-icon-edit" circle>
                                            </el-button> 
                                            <el-button v-if="scope.row.type == 'package'"
                                                @click="showContainerProducts(scope.row)" 
                                                type="primary" icon="el-icon-view" circle>
                                            </el-button> 
                                            <el-button v-if="scope.row.type == 'product' && scope.row.parcel"
                                                @click="updateParcelProducts(scope.row)" 
                                                type="primary" icon="el-icon-view" circle>
                                            </el-button> 
                                        </template>
                                    </el-table-column>

                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <i v-show="form.in_filial" @click="removeItem(scope.$index)" class="el-icon-delete icon__delete"></i>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="mt-4">
                                <el-row :gutter="20">
                                    <el-col :span="20">
                                        <el-input
                                            id="prod" 
                                            size="medium" 
                                            class="w-100"
                                            ref="scanRef"
                                            @input="searchProductDebounce" 
                                            :placeholder="$t('message.scan')" 
                                            v-model="barcode_search"
                                        >
                                        </el-input>
                                    </el-col>
                                    <el-col :span="4" v-if="form.to_filial_id" style="text-align: right;">
                                        <el-button class="w-100" type="primary" size="medium" @click="showDirectionProducts()">{{$t('message.products')}}</el-button>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        
        <!-- Product partiyalar bo'yicha -->
        <el-dialog 
            :append-to-body="true"
            width="80%"
            :title="$t('message.product_inventorization')" 
            :visible.sync="batchDialog">
            <div v-if="selectedProduct.batches" class="mm_custom_input_padding">
                <el-table :data="selectedProduct.batches" border stripe>
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column>  
                    <el-table-column :label="$t('message.name')">
                        <template> {{ selectedProduct.product ? selectedProduct.product.name : '' }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.quantity_y')" width="80">
                        <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.barcode')">
                        <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.current_quantity')"  width="95">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                :disabled="disabledChangeValue"
                                @input="checkValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight.title" width="130">
                        <template slot-scope="scope">
                            <el-input 
                                class="mm_custom_input"
                                :min="0" 
                                type="number" 
                                size="mini" 
                                :disabled="true"
                                v-model="scope.row.weight"
                            >
                                <template slot="append"></template>
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.item_weight.title" width="130">
                        <template slot-scope="scope">
                            <el-input 
                                class="mm_custom_input"
                                :min="0" 
                                type="number" 
                                size="mini" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                v-model="scope.row.item_weight"
                            >
                                <template slot="append">{{ $t('message.kg') }}</template>
                            </el-input>

                            <el-input 
                                class="mt-1 mm_custom_input"
                                :min="0" 
                                type="number" 
                                size="mini" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                v-model="scope.row.item_weight_in_pounds"
                            >
                                <template slot="append">{{ $t('message.lbs') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.width.title" v-if="columns.width.show" width="110">
                        <template slot-scope="scope">
                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'width')"
                                v-model="scope.row.width"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                class="mt-1 mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                                v-model="scope.row.width_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.height.title" v-if="columns.height.show" width="110">
                        <template slot-scope="scope">
                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'height')"
                                v-model="scope.row.height"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                class="mt-1 mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                                v-model="scope.row.height_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.length.title" v-if="columns.length.show" width="110">
                        <template slot-scope="scope">
                            <el-input
                                class="mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'length')"
                                v-model="scope.row.length"
                            >
                                <template slot="append">{{ $t('message.sm') }}</template>
                            </el-input>

                            <el-input
                                class="mt-1 mm_custom_input"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                                v-model="scope.row.length_on_inches"
                            >
                                <template slot="append">{{ $t('message.inch') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.comment')">
                        <template slot-scope="scope"> {{ scope.row.comment }} </template>
                    </el-table-column>
                </el-table>

                <div class="mt-4 cargo__right" v-if="!disabledChangeValue">
                    <el-button @click="updateIncomingProducts()" type="primary" plain>{{ $t('message.save') }}</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- Upakovka productlari -->
        <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="emptyCortainerProducts()" :append-to-body="true" >
            <div v-loading="loadingProducts">
                <el-table :data="containerProducts">
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column>  
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="comment" :label="$t('message.comment')">
                        <template slot-scope="scope">
                            {{ scope.row.comment ? scope.row.comment : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                    <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                    <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                    <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                </el-table>
            </div>
        </el-dialog>

        
         <!-- Product posilki -->
        <el-dialog 
            class="dialog__modal" 
            :title="$t('message.products')" 
            :visible.sync="dialogParcelProductsList" 
            :append-to-body="true"
            width="60%" 
            @opened="$refs['parcelProducts'] ? $refs['parcelProducts'].afterOpen() : ''">
            <parcel-products-list ref="parcelProducts" :selectedItem="selectedProd"></parcel-products-list>
        </el-dialog>

        <el-drawer
            :with-header="false" 
            class="bg-se"
            size="90%" 
            :visible.sync="drawerProductList"
            :append-to-body="true"
            ref="drawerProductList"
            @opened="drawerOpened('drawerProductListChild')"
            @closed="drawerClosed('drawerProductListChild')"
            >
            <IncomingCargoList
                ref="drawerProductListChild"
                @mergeToProductList="mergeToProductList"
                :payload_for_prod_list="form"
                drawer="drawerProductList"
                > 
            </IncomingCargoList>
        </el-drawer>
    </div>
</template>
    
<script>
import IncomingCargoList from "./incoming-cargo-list";
import { mapGetters, mapActions } from "vuex";
import show from "@/utils/mixins//show";
import transfer_mix from "@/utils/mixins/views/transfer_mix";
import product_calculation_functions from "@/utils/mixins/product_calculation_functions";

export default {
    mixins: [show, transfer_mix, product_calculation_functions],
    components: {
        IncomingCargoList
    },
    data: () => ({
        transfer_type: 'recieving',
    }),
    computed: {
        disabledChangeValue(){
            if(!this.form.in_filial || this.form.airway_bill_number){
                return true;
            }
            return false;
        },

        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.type === 'product'){
                    if(product.batches && product.batches.length > 1){
                        product.batches.forEach(batch => {
                            total += parseFloat(batch.weight);
                        });
                    }else{
                        total += parseFloat(product.weight);
                    }
                }
                if(product.type === 'package'){
                    total += parseFloat(product.total_weight);
                }
                
            });
            return parseFloat(total);
        },
    },
    methods: {
        ...mapActions({
            receiveInFilial: "transferingCargo/receiveInFilial",
            show: "transferingCargo/show",
            incomingTransferCargo: "transferingCargo/incomingTransferCargo",
            destroy: "productCirculations/destroy",
            emptyCortainerProducts: "packageForProductCirculations/empty",
            getProductByBarcode: 'productCirculations/getProductByBarcode',
            updatePackages: 'packageForProductCirculations/inventory',
        }),

        mergeToProductList(listOfSelected){
            listOfSelected.forEach(element => {
                this.get_selected_product({product: element, barcode: '', type: element.type, from_list: true})
            });
        },
          
        updateParcelProducts(parcel) {
            this.selectedProd = parcel;
            this.dialogParcelProductsList = true;
        },
        
        fetchData() {
            if(this.$refs['scanRef']){
                this.$refs['scanRef'].focus(); 
            }
        },

        async updateIncomingProducts(){
            await this.updateBatches();
            this.selectedProduct = {};
            this.batchDialog = false;
        },

        showAndUpdateBatches(row){
            this.batchDialog = true;
            if(this.disabledChangeValue){
                this.selectedProduct = JSON.parse(JSON.stringify(row));
                this.$set(this.selectedProduct, 'batches', row.batches.filter(el => (parseFloat(el.incoming_quantity) > 0)))
            }else{
                this.selectedProduct = JSON.parse(JSON.stringify(row));
            }
        },

        get_selected_product({product, barcode, type, from_list = false}){
            if(type === 'product' && product && this.dealProductsList.filter(el => el.id === product.id).length === 0){
                let prod = JSON.parse(JSON.stringify(product));
                this.$set(prod, 'type', type);
                if(barcode && prod.batches && prod.batches.length <= 1){
                    this.$set(prod, 'incoming_quantity', parseFloat(1));
                }else if(barcode && prod.batches && prod.batches.length > 1){
                    let index = prod.batches.map(e => e.barcode).indexOf(barcode);
                    this.$set(prod, 'incoming_quantity', parseFloat(1));
                    this.$set(prod.batches[index], 'incoming_quantity', parseFloat(1));
                }else if(!from_list){
                    this.$set(prod, 'incoming_quantity', parseFloat(0));
                }

                this.dealProductsList.push(prod);
                this.product_id = null;
            }else if(type === 'product' && product && barcode){
                let index = this.dealProductsList.map(e => e.id, product.id).indexOf(product.id);
                if(this.dealProductsList[index].batches && this.dealProductsList[index].batches.length > 1){
                    this.dealProductsList[index].batches.forEach(el => {
                        if(el.barcode === barcode){
                            if(el.incoming_quantity < el.remainder) {
                                el.incoming_quantity ++;
                                this.dealProductsList[index].incoming_quantity ++;
                            }else{
                                this.insufficiant_prod_message();
                            }
                            
                        }
                    })
                }else{
                    if(this.dealProductsList[index].incoming_quantity < this.dealProductsList[index].remainder) {
                        this.dealProductsList[index].incoming_quantity ++;
                    }else{
                        this.insufficiant_prod_message();
                    }
                }
            }else if(type === 'package' && product && this.dealProductsList.filter(el => (el.id === product.id && el.type === type)).length === 0){
                let prod = JSON.parse(JSON.stringify(product));
                this.$set(prod, 'type', type);
                this.dealProductsList.push(prod);
            }else{
                this.$notify({
                    title: this.$t('message.product'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.product_already_exists')
                });
            }
        },

        submit(close = true) {
            let check_empty_prods = this.dealProductsList.filter(el => el.incoming_quantity === 0);
            if(this.dealProductsList.length > 0 && check_empty_prods.length === 0 && this.total_weight > 0){
                this.form.products = this.dealProductsList
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.receiveInFilial(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged();
                                if (close) this.close();
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.check_prod_quantity')
                });
            }
        },

    },
    
}
</script>
    